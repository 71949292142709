<template>
  <div class="balaceBox">
    <div class="numWraper">
      当前成长值 <span class="balaceNum">{{  PointsNum>0 ? PointsNum : 0 }}</span>
    </div>
    <p class="progressBar"></p>
    <el-row class="fromWraper">
      全部记录
    </el-row>
    <el-row class="BoxTable">
      <ul class="ulBox">
        <li>
          <el-date-picker
              format="yyyy 年 MM 月"
              :picker-options="pickerBeginDateBefore"
              clear-icon=""
              prefix-icon="el-icon-arrow-down"
              v-model="dateValue"
              size="small"
              type="month"
              @change="ChangeDate"
            >
          </el-date-picker>
        </li>
        <li>交易名称</li>
        <li>交易记录</li>
      </ul>        <!--  label="交易时间"          label="交易金额"-->
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        :show-header="false"
        class="wraper"
        style="width: 100%;"
      >
        <el-table-column class="text-center" prop="createTime"  width="360" align="left" >
           
        </el-table-column>
        <el-table-column
          class="text-center"
          prop="type"
          width="380"
           label="交易名称"
           align="center" 
        >
        </el-table-column>

        <el-table-column
          class="text-center"
          prop="growthValue"
          label="交易记录"
          width="380"
          align="center" 
        >
        </el-table-column>
      </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="false"
            prev-text="上一页"
            next-text="下一页"
          @current-change="handleCurrentChange"
            :current-page="current"
          :page-size="size"
          :total="total">
        </el-pagination>
    </el-row>
  </div>
</template>
<style lang="less">
.balaceBox {
  .el-date-editor{
    .el-input__prefix{
      right: -20px;
    }
  }
  .el-input__inner{
    border: none;
    background: #f6f6f6;
  }
  .el-table{
    margin: 0 auto;
  }
  .el-table .cell{
    font-size: 12px;
    color: #333;
  }
     .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #ff3333; 
        color: #fff;
    }
/* 上一页，下一页样式 */
.el-pagination button, .el-pagination span:not([class*=suffix]){
    background: #fff;
    color: #666;
     padding: 4px;
     box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li{
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover{
    color:#ff3333; 
    font-size: 14px;
}
}
</style>

<style lang="less" scoped>
@import "~style/index.less";
  .BoxTable{
    padding: 0 20px 50px 20px;
    background: #fff;
  }
 .el-pagination {
     padding-top:20px;
     text-align: right
     }
.balaceBox {
  width:1160px;
  padding:0 20px ;
   margin:30px auto;
  .progressBar{
    height: 20px;
    margin-left:-20px;
    width: 100%;
    background: #f6f6f6;
    padding: 0 20px;
  }
  .fromWraper{
     padding:20px 20px  0 20px;
     background: #fff;
     height:68px;
     line-height: 30px;
     font-size:15px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }
  .ulBox{
       background: #f6f6f6;
       height: 38px;
       line-height: 30px;
       li{
         float: left;
         text-align: center;
         color:#666666;
        line-height: 38px;
        font-size: 12px;
        width:380px;
       }
       li:first-child{
        width:360px;
        text-align: left;
      }
      li:last-child{
         width:380px;
      }
     }
  .numWraper {
    background: #fff;
    padding:0 20px;
    font-size: 16px;
      color: #333;
      line-height: 65px;
      display: flex;
    justify-content: flex-start;
    .balaceNum {
      padding-left:16px;
      font-weight: bold;
      font-size:30px;
      color: #E00B0BFF;
    }
  }

}
</style>

<script>
//  ,userPointsNum  ,pointsLogList
import {GrowValList} from 'api/member.js';
export default {
  name: "Balance",
  data() {
    return {
      total:0,
       current:1,//当前页
       size:10,//每页数量
      //日期选择框不能超过当前时间
      time:'',
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      //选项卡默认选择第一项
      currentIndex: 0,
      //加载loading条
      loading: false,
      PointsNum: 0,
      PointsId:'',
      //日期选择框默认是当前月
      dateValue: new Date(),
     
      //导航栏信息
      obj:{
            content:[
              {
                type:1,
                name:"全部记录"
              },
              {
                type:2,
                name:"获取积分记录"
              },
              {
                type:3,
                name:"消费积分记录"
              }
            ],
            currentIndex:0
      },
      tableData: [
     
      ],
    };
  },
  methods: {
      
    ChangeDate(){
       let currentTime = new Date(this.dateValue), year = currentTime.getFullYear(), month = currentTime.getMonth()+1
       if(month>9){
        this.time=year+'-'+month+'-'

       }else{
         this.time=year+'-0'+month+'-'
       }
       this.current=1
       this.PointsDetailsFun(this.obj.currentIndex+1);
    },
     changePropsIndex(params){
        this.obj.currentIndex = params;
        this.current=1;
        this.PointsDetailsFun(params+1)
      },
      
      handleCurrentChange(val) {
        this.current=val
          this.PointsDetailsFun();
      },
      // 积分明细接口
      PointsDetailsFun(){

         let datas={};
         datas={
               current:this.current,//当前页
              size:this.size,//每页数量
               time:this.time,
            }
         // 显示loading   
     this.$loading({ fullscreen: true ,background:'#ffffff45'})
         GrowValList(datas).then(res=>{


          console.log(datas)
          console.log(res.data.data)
              this.tableData=res.data.data.records

              this.tableData.forEach(item=>{
                if(item.type==1){
                  item.type='订单获取成长值'
                  item.growthValue='+'+item.growthValue
                }
                if(item.type==2){
                  item.type='季度末清除成长值'
                  item.growthValue='-'+item.growthValue
                }
                if(item.type==3){
                  item.type='线下订货成功'
                  item.growthValue='+'+item.growthValue
                }
                if(item.type==4){
                  item.type='线下退货成功'
                  item.growthValue='-'+item.growthValue
                }
              })
              // 初始获取所有数据数量
              this.total=res.data.data.total;
               // 关闭loading
              this.$loading().close();
          })
      }
   
  },
  created() {
    let currentTime = new Date(this.dateValue), year = currentTime.getFullYear(), month = currentTime.getMonth()+1
    if(month>9){
    this.time=year+'-'+month+'-'
    }else{
      this.time=year+'-0'+month+'-'
    }

    this.PointsNum=this.$route.query.nums

    this.PointsDetailsFun();
   
  },
  mounted() {},
  components: {
  },
  computed: {},
};
</script>
